
<template>
  <div class="order-sample-analyses-index width-100">
    <h2
      v-if="sampleDetails"
      class="order-sample-analyses-index__sample-details"
    >
      {{ sampleDetails.productName }} - {{ sampleDetails.labSampleCode }}
    </h2>
    <Filters
      :filters="orderSampleAnalysesFilters"
      filter-key="orderSampleAnalyses"
      :initial-data="filtersStatus.orderSampleAnalyses"
    />
    <TableWrapper
      :enable-selection="canUse(['admin', 'ordersManage'], true)"
      :table-columns="orderSampleAnalysesColumns"
      :massive-actions-options="massiveActionsOptions"
      table-key="orderSampleAnalyses"
      @selectionChange="selectionChange"
      @massiveAction="bulkApplyStatus"
      @downloadExcel="downloadExcel"
    />
  </div>
</template>

<script>
import fileSaver from 'file-saver';
import { mapGetters, mapActions } from 'vuex';

import { orderSampleAnalysesColumns, orderSampleAnalysesFilters } from '@/config';
import { ANALYSIS_STATUSES_DESC } from '@/config/constants';
import { samplesApi, ordersApi } from '@/api';
import acl from '@/services/acl';
import TableWrapper from '@/components/TableWrapper.vue';
import Filters from '@/components/Filters.vue';

export default {
  name: 'OrderSampleAnalysesIndex',
  components: {
    TableWrapper,
    Filters,
  },
  data() {
    return {
      orderSampleAnalysesColumns,
      orderSampleAnalysesFilters,
      selectedAnalyses: [],
      massiveActionsOptions: Object.keys(ANALYSIS_STATUSES_DESC)
        .map(key => ({ label: `enums.analysisStatuses.${ANALYSIS_STATUSES_DESC[key]}`, value: key })),
      sampleDetails: null,
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
    ]),
  },
  async created() {
    this.parseQueryStringStatus(this.$route.query);
    this.tableRows();
    const sampleDetails = await ordersApi
      .showSample(this.$route.params.orderId, this.$route.params.sampleId);
    this.sampleDetails = sampleDetails.data;
  },
  async beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.tableRows();
    const sampleDetails = await ordersApi
      .showSample(this.$route.params.orderId, this.$route.params.sampleId);
    this.sampleDetails = sampleDetails.data;
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'parseQueryStringStatus',
    ]),
    async tableRows() {
      try {
        const indexResponse = await samplesApi.sampleAnalysesIndex(
          this.$route.params.sampleId,
          this.tableFilters('orderSampleAnalyses'),
          this.filtersStatus.orderSampleAnalyses,
        );
        const countResponse = await samplesApi.sampleAnalysesCount(
          this.$route.params.sampleId,
          this.filtersStatus.orderSampleAnalyses,
        );
        this.updateTableRows({ tableKey: 'orderSampleAnalyses', rows: indexResponse.data });
        this.updateTableCount({ tableKey: 'orderSampleAnalyses', count: countResponse.data.count });
      } catch (error) {
        throw error;
      }
    },
    selectionChange(val) {
      this.selectedAnalyses = val;
    },
    async bulkApplyStatus(massiveActionsValue) {
      try {
        if (this.selectedAnalyses.length) {
          const buildWhereCondition = selectedAnalyses => ({
            id: {
              inq: selectedAnalyses.map(a => a.id),
            },
          });
          await samplesApi.bulkUpdateSampleAnalyses(
            this.$route.params.sampleId,
            { status: parseInt(massiveActionsValue, 10) },
            buildWhereCondition(this.selectedAnalyses),
          );
          this.tableRows();
        }
        this.$notify({ showClose: true, type: 'success', message: this.$t('samples.update.success') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async downloadExcel() {
      try {
        this.loading = true;
        const file = await samplesApi.downloadExcel(this.$route.params.orderId);
        if (file.data) {
          return fileSaver.saveAs(URL.createObjectURL(file.data), `export-order-${this.$route.params.orderId}.zip`);
        }
        this.loading = false;
        return file;
      } catch (error) {
        this.loading = false;
        return error;
      }
    },
    canUse(permissions, checkAll = false) {
      return acl.userCanUse(permissions, checkAll);
    },
  },
};
</script>

<style lang="scss">

.order-sample-analyses-index {

  &__sample-details {
    padding:0  0 $--main-padding 0;
    color: $--color-primary;
    // text-transform: uppercase;
  }

  .el-table__body tr:hover > td.table-cell-status {
    @each $modifier, $map in $analysis-statuses {
      &--#{$modifier} {
        filter: brightness(0.9);
        background-color: map-get($map, 'background');
      }
    }
  }

  .table-cell-status {
    @each $modifier, $map in $analysis-statuses {
      &--#{$modifier} {
        background-color: map-get($map, 'background');
        color: map-get($map, 'color');
      }
    }
  }
}

</style>
